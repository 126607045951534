// Colors
// ------

// These ones will replace theme-colors
// (https://getbootstrap.com/docs/5.0/customize/sass/#modify-map)
$primary: #8BD0FC;
$primaryDark: #103D73;
$secondary: #FCE76C;
$secondaryDark: #FADC4C;
$accent: #FF8F5C;
$success: #81E5B1;
$danger: #E8795E;
$subdued: #999EAA;
$lightShade: rgba(211, 218, 230, 0.32);

// These ones will be added to theme colors
// https://getbootstrap.com/docs/5.0/customize/sass/#add-to-map

$custom-colors: (
    "primary-dark":   $primaryDark,
    "secondary-dark": $secondaryDark,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

// Body and headings style
$gray-700: #495057 !default;
$font-size-base: 1.0rem;
$body-color: $gray-700;

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.75;
$h3-font-size:                $font-size-base * 1.5;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base;

// Fix for production build...
// https://github.com/roots/sage/issues/2017
$navbar-dark-toggler-icon-bg:           url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='3' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
$navbar-light-toggler-icon-bg:          url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='3' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
$navbar-dark-toggler-expanded-icon-bg:  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='4' stroke-miterlimit='10' d='M 3.940678,4.3220339 24.915254,25.29661 M 24.915254,4.3220339 3.940678,25.29661'/%3E%3C/svg%3E");
$navbar-light-toggler-expanded-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='4' stroke-miterlimit='10' d='M 3.940678,4.3220339 24.915254,25.29661 M 24.915254,4.3220339 3.940678,25.29661'/%3E%3C/svg%3E");
// And colors
$navbar-dark-color:                 rgba(white, .9);
$navbar-dark-hover-color:           rgba(white, 1.0);
//$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba(white, .5);


// Header and footer
$header-height: 55px;
$footer-height: 55px;
$footer-margin: 30px;
