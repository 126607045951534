// Bootstrap functions and variables first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

//-- Custom variables
@import "variables";

//-- Bootstrap (overriding functions and variables imported before)
// Import only some features of bootstrap: https://getbootstrap.com/docs/5.0/customize/sass/#importing
@import "~bootstrap/scss/bootstrap";

// //-- Font awesome
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts" !default;
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
// @import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";

//-- Flag-icon-css
$flag-icons-path: "~flag-icons/flags" !default;
$include: 'fr', 'gb', 'es', 'de';
@import "~flag-icons/sass/flag-icons";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;800&display=swap');

// // Datatables-Bootstrap 4 styling
// @import "../../node_modules/datatables.net-bs4/css/dataTables.bootstrap4.css";

// // Select2 styling
// @import "../../node_modules/select2/dist/css/select2.css";
// @import "../../node_modules/select2-bootstrap4-theme/dist/select2-bootstrap4.css";
//
// //-- jquery-datepicker
// @import '../../node_modules/jquery-ui/themes/base/base.css';
// @import '../../node_modules/jquery-ui/themes/base/theme.css';
// @import '../../node_modules/jquery-ui/themes/base/datepicker.css';

// Custom app styling
@import "content";
